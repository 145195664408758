import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Form, Row } from 'antd/es'
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select'

import { FilterKey } from '..'
import { orderBy } from 'lodash'

import { PageFilter } from '@cozero/models'

import Select from '@/atoms/Select'

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Select {
  export type value = number[]
  export type option = BaseOptionType | DefaultOptionType | (BaseOptionType | DefaultOptionType)[]
}

interface Props {
  featuresAllowed: string[]
  filters: (PageFilter & { key: FilterKey })[]
  filterOptions: (PageFilter & {
    options: {
      key?: string
      value: string
      label: string
    }[]
  })[]
  values: {
    [FilterKey.ACTIVITY_DATA_SOURCE]: number[]
    [FilterKey.TERRITORY]: number[]
    [FilterKey.UNIT]: number[]
    [FilterKey.FACTOR_ORIGIN]: number[]
  }
  onChange: (filter: PageFilter & { key: FilterKey }, value: Select.value | string) => void
}

const LocationFiltersForm = ({
  filterOptions,
  values,
  onChange,
  featuresAllowed,
}: Props): ReactElement => {
  const { t } = useTranslation('')
  const multipleAllowed = featuresAllowed?.includes('multiple-filtering')

  const handleChange = (value: Select.value | string, key: string): void => {
    const selectedFilter = filterOptions?.find((x) => x.key === key)
    if (selectedFilter) {
      onChange(selectedFilter as PageFilter & { key: FilterKey }, value)
    }
  }

  const {
    activityDataSource: activityDataSourceOptions,
    territory: territoryOptions,
    unit: unitOptions,
    origin: factorOriginOptions,
  } = useMemo(() => {
    return filterOptions.reduce(
      (acc, value) => {
        return {
          ...acc,
          [value.key as string]: orderBy(
            value.options?.map((x) => ({
              ...x,
              value: +x.value,
              key: x.key ? +x.key : x.key,
              label: x.label.trim(),
            })) || [],
            ['label'],
            ['asc'],
          ),
        }
      },
      {
        [FilterKey.ACTIVITY_DATA_SOURCE]: [],
        [FilterKey.TERRITORY]: [],
        [FilterKey.UNIT]: [],
        [FilterKey.FACTOR_ORIGIN]: [],
      },
    )
  }, [filterOptions])

  return (
    <>
      <Form layout="vertical" hideRequiredMark>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={t('cozero.activityDataSource.title')}>
              <Select
                mode={multipleAllowed ? 'multiple' : undefined}
                size="large"
                placeholder={t('log.filter.placeholder')}
                fieldNames={{ label: 'label', value: 'value' }}
                optionFilterProp="label"
                value={values.activityDataSource}
                options={activityDataSourceOptions}
                onChange={(value) => {
                  const cleanValue = multipleAllowed ? value : [value]
                  handleChange(cleanValue, FilterKey.ACTIVITY_DATA_SOURCE)
                }}
                style={{ width: '100%' }}
                showSearch={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={t('cozero.territory.title')}>
              <Select
                mode={multipleAllowed ? 'multiple' : undefined}
                size="large"
                placeholder={t('log.filter.placeholder')}
                fieldNames={{ label: 'label', value: 'value' }}
                optionFilterProp="label"
                value={values.territory}
                options={territoryOptions}
                onChange={(value) => {
                  const cleanValue = multipleAllowed ? value : [value]
                  handleChange(cleanValue, FilterKey.TERRITORY)
                }}
                style={{ width: '100%' }}
                showSearch={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={t('cozero.unit.title')}>
              <Select
                mode={multipleAllowed ? 'multiple' : undefined}
                size="large"
                placeholder={t('log.filter.placeholder')}
                fieldNames={{ label: 'label', value: 'value' }}
                optionFilterProp="label"
                value={values.unit}
                options={unitOptions}
                onChange={(value) => {
                  const cleanValue = multipleAllowed ? value : [value]
                  handleChange(cleanValue, FilterKey.UNIT)
                }}
                style={{ width: '100%' }}
                showSearch={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={t('cozero.factorOrigin.title')}>
              <Select
                mode={multipleAllowed ? 'multiple' : undefined}
                size="large"
                placeholder={t('log.filter.placeholder')}
                fieldNames={{ label: 'label', value: 'value' }}
                optionFilterProp="label"
                value={values.origin}
                options={factorOriginOptions}
                onChange={(value) => {
                  const cleanValue = multipleAllowed ? value : [value]
                  handleChange(cleanValue, FilterKey.FACTOR_ORIGIN)
                }}
                style={{ width: '100%' }}
                showSearch={true}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default React.memo(LocationFiltersForm)
