import { TreeNodeDatum } from 'react-d3-tree/lib/types/common'

import { ShapeAttrs, Types } from '@antv/g2'

import {
  CINDER_BLUE_20,
  CINDER_BLUE_30,
  CINDER_BLUE_50,
  CINDER_BLUE_BASE,
  COZERO_BLUE_30,
  COZERO_BLUE_BASE,
  COZERO_GREEN_30,
  COZERO_GREEN_70,
  COZERO_ORANGE_30,
  COZERO_ORANGE_70,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_SEMIBOLD,
  RED_20,
  RED_80,
  SIZE_FONT_TEXT_MD,
  SIZE_FONT_TEXT_SM,
  SIZE_FONT_TEXT_XS,
  YELLOW_20,
  YELLOW_BASE,
} from '@/styles/variables'
import { formatCompact } from '@/utils/number'

export const theme = {
  textColor: '#7b7d86',
  fontSize: 14,
  fontFamily: 'Inter',
  legends: {
    text: {
      color: '#7b7d86',
      fontSize: 14,
      fontFamily: 'Inter',
    },
  },
}

export type ICharts = {
  bar: JSX.Element
  pie: JSX.Element
  line: JSX.Element
  statistic: JSX.Element
  table: JSX.Element
  forecast: JSX.Element
}

// https://colorbrewer2.org/#type=qualitative&scheme=Paired&n=12
export const colors = [
  COZERO_BLUE_BASE,
  COZERO_ORANGE_70,
  COZERO_GREEN_70,
  YELLOW_BASE,
  COZERO_GREEN_70,
  COZERO_ORANGE_70,
  YELLOW_BASE,
  CINDER_BLUE_BASE,
  RED_80,
  COZERO_BLUE_30,
  COZERO_ORANGE_30,
  COZERO_GREEN_30,
  YELLOW_20,
  YELLOW_20,
  CINDER_BLUE_20,
  RED_20,
]

const remToPx = (rem: string): number => {
  return Math.round(Number(rem) * 16)
}

export const axisTextStyle: ShapeAttrs = {
  fontSize: remToPx(SIZE_FONT_TEXT_XS),
  fontFamily: 'Inter',
  fontWeight: parseInt(FONT_WEIGHT_SEMIBOLD),
  fill: '#9D9FA9',
}

export const breadcrumbsTextStyle: ShapeAttrs = {
  ...axisTextStyle,
  fontSize: remToPx(SIZE_FONT_TEXT_SM),
}

export const legendStyles: Types.LegendCfg = {
  itemName: {
    formatter: (val) => val,
    style: {
      fill: CINDER_BLUE_50,
      fontSize: remToPx(SIZE_FONT_TEXT_MD),
      fontWeight: parseInt(FONT_WEIGHT_MEDIUM),
    },
  },
  marker: {
    symbol: 'circle',
  },
  layout: 'horizontal',
  position: 'top-left',
  label: {
    formatter: (val) => {
      if (!val) {
        return ''
      } else if (typeof val === 'number') {
        return val.toLocaleString()
      }
      return val
    },
  },
}

export const yAxisStyles: Types.AxisCfg = {
  grid: {
    line: {
      style: {
        lineWidth: 1,
        lineDash: [3, 3],
        opacity: 0.7,
      },
    },
  },
  title: {
    style: axisTextStyle,
  },
  label: {
    style: axisTextStyle,
    formatter(text) {
      if (!isNaN(Number(text))) {
        return formatCompact(Number(text))
      }
      return text
    },
  },
}

export const xAxisStyles: Types.AxisCfg = {
  label: {
    style: axisTextStyle,
  },
}

export const columnStyles: ShapeAttrs = {
  stroke: 'white',
  strokeOpacity: 1,
  lineWidth: 2,
  radius: 4,
}

export const padding = [30, 0, 0, 0]

export const scopeAttributes = [
  { name: 'Scope', scope: 1, color: COZERO_BLUE_BASE },
  { name: 'Scope', scope: 2, color: COZERO_GREEN_70 },
  { name: 'Scope', scope: 3, color: COZERO_ORANGE_70 },
]

export function getNodeColor(node: TreeNodeDatum): string {
  const root = node.__rd3t.depth === 0

  if (root) {
    return CINDER_BLUE_30
  }

  return 'white'
}
