import { RawNodeDatum } from 'react-d3-tree/lib/types/common'

import { omit } from 'lodash-es'

import { BusinessUnit, Location } from '@cozero/models'
import { EmissionsSumGroupResultItem } from '@cozero/models'

export interface MappedEmissionsSumGroupResultItem {
  scope1: number
  scope2: number
  scope3: number
  total: number
}

export function updateTreeData(
  list: BusinessUnit[],
  id: React.Key,
  children: BusinessUnit[],
): BusinessUnit[] {
  const cleanChildren = children.map((child) => {
    if (child.children?.length === 0) {
      child = omit(child, 'children') as BusinessUnit
    }

    return child
  })

  return list.map((node) => {
    if (node.id === id) {
      return {
        ...node,
        children: cleanChildren,
      }
    }

    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children as BusinessUnit[], id, cleanChildren),
      }
    }

    return node
  })
}

export const getEmissionsMapping = (
  items: EmissionsSumGroupResultItem[],
): Record<string, MappedEmissionsSumGroupResultItem> => {
  return items.reduce<Record<string, MappedEmissionsSumGroupResultItem>>(
    (mappedBusinessUnitEmissions, item) => {
      const scope1 = item?.scope.find((scope) => scope.scope === 1)?.total ?? 0
      const scope2 = item?.scope.find((scope) => scope.scope === 2)?.total ?? 0
      const scope3 = item?.scope.find((scope) => scope.scope === 3)?.total ?? 0
      const total = item?.total ?? 0

      return {
        ...mappedBusinessUnitEmissions,
        [item.id]: { scope1, scope2, scope3, total },
      }
    },
    {},
  )
}

export const composeGraphData = (
  businessUnit: BusinessUnit,
  emissionsByGroup: {
    businessUnits: EmissionsSumGroupResultItem[]
    locations: EmissionsSumGroupResultItem[]
  },
): RawNodeDatum[] => {
  const businessUnitsMapping = getEmissionsMapping(emissionsByGroup.businessUnits)
  const locationsMapping = getEmissionsMapping(emissionsByGroup.locations)

  return mapEntityEmissions([businessUnit], businessUnitsMapping, locationsMapping)
}

const aggregateEmissionResultItems = (
  emissions: MappedEmissionsSumGroupResultItem[],
): MappedEmissionsSumGroupResultItem => {
  return emissions.reduce(
    (aggregated, emissions) => {
      aggregated.scope1 = aggregated.scope1 + emissions.scope1
      aggregated.scope2 = aggregated.scope2 + emissions.scope2
      aggregated.scope3 = aggregated.scope3 + emissions.scope3
      aggregated.total = aggregated.total + emissions.total

      return aggregated
    },
    { scope1: 0, scope2: 0, scope3: 0, total: 0 },
  )
}

export const mapEntityEmissions = (
  entities: (BusinessUnit | Location)[],
  businessUnitsMapping: Record<string, MappedEmissionsSumGroupResultItem>,
  locationsMapping: Record<string, MappedEmissionsSumGroupResultItem>,
): RawNodeDatum[] => {
  return entities.map((entity) => {
    const isBusinessUnit = 'ancestorIds' in entity

    const baseAttributes = {
      id: entity.id,
      ...(entity.metadata?.externalId ? { externalId: entity.metadata?.externalId } : {}),
    }

    if (isBusinessUnit) {
      // Children emissions calculations
      const children = mapEntityEmissions(
        [...(entity.children || []), ...(entity.locations || [])] as BusinessUnit[],
        businessUnitsMapping,
        locationsMapping,
      )

      const businessUnitChildrenIds = children
        .filter((child) => child.attributes?.type === 'Business unit')
        .map((child) => Number(child.attributes?.id))

      const businessUnitChildrenEmissions = businessUnitChildrenIds.map(
        (id) => businessUnitsMapping[id],
      )

      // Current business unit emissions calculations
      const {
        scope1 = 0,
        scope2 = 0,
        scope3 = 0,
        total = 0,
      } = businessUnitsMapping[entity.id] ?? {}

      const {
        scope1: childrenScope1,
        scope2: childrenScope2,
        scope3: childrenScope3,
        total: childrenTotal,
      } = aggregateEmissionResultItems(businessUnitChildrenEmissions)

      // Store calculated emissions to be used for higher levels
      businessUnitsMapping[entity.id] = {
        scope1: scope1 + childrenScope1,
        scope2: scope2 + childrenScope2,
        scope3: scope3 + childrenScope3,
        total: total + childrenTotal,
      }

      return {
        name: entity.title,
        attributes: {
          ...baseAttributes,
          type: 'Business unit',
          organizationName: entity.organization?.name,
          scope1Emissions: scope1 + childrenScope1,
          scope2Emissions: scope2 + childrenScope2,
          scope3Emissions: scope3 + childrenScope3,
          totalEmissions: total + childrenTotal,
        },
        children,
      }
    }

    const { scope1 = 0, scope2 = 0, scope3 = 0, total = 0 } = locationsMapping[entity.id] ?? {}

    const responsibleName =
      entity.responsible?.firstName && entity.responsible?.lastName
        ? `${entity.responsible?.firstName} ${entity.responsible?.lastName}`
        : ''

    return {
      name: entity.name,
      attributes: {
        ...baseAttributes,
        type: 'Location',
        organizationName: entity.organization?.name,
        responsibleName,
        scope1Emissions: scope1,
        scope2Emissions: scope2,
        scope3Emissions: scope3,
        totalEmissions: total,
      },
      children: [],
    }
  })
}
