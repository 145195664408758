import { RootState } from '..'
import { createSelector } from '@reduxjs/toolkit'

import { BusinessUnit, Feature, Organization, User } from '@cozero/models'

import { AuthState } from './slice'

// Selectors
export const selectUser = (state: RootState): AuthState['user'] => state.auth.user
export const selectToken = (state: RootState): AuthState['token'] => state.auth.token
export const selectUserOrganization = (state: RootState): Organization | undefined =>
  state.auth?.organization as Organization | undefined
export const selectLocale = (state: RootState): User['locale'] | undefined =>
  state.auth.user?.locale

// Memoized getters
export const getUserRole = createSelector([selectUser], (user) => user?.role?.type)
export const getIsAdmin = createSelector(selectUser, (user) => user?.role?.type === 'admin')
export const getIsCozeroAdmin = createSelector(
  selectUser,
  (user) => user?.role?.type === 'cozero-admin',
)
export const getIsManager = createSelector(selectUser, (user) => user?.role?.type === 'manager')
export const getIsManagerOrAdmin = createSelector(
  selectUser,
  (user) => user?.role?.type === 'admin' || user?.role?.type === 'manager',
)
export const getIsUserReadOnly = createSelector(selectUser, (user) => user?.role?.type === 'viewer')
export const getIsRootBusinessUnitUser = createSelector(
  selectUser,
  (user) => user?.businessUnit?.ancestorIds.length === 0,
)
export const getHasCozeroEmail = createSelector(
  selectUser,
  (user) => user?.email?.endsWith('@cozero.io') ?? false,
)
export const getBelongsToLiveOrganisation = createSelector(
  selectUser,
  (user) => (user?.organization?.metadata as { mode: string })?.mode === 'live',
)

export const getFeaturesAllowed = createSelector([selectUserOrganization], (organization) =>
  organization?.pricing?.features?.map(({ key }) => key),
)
export const getIsFeatureAllowed = createSelector(
  [selectUserOrganization, (_state, feature: Feature['key']) => feature],
  (organization, feature) =>
    organization?.pricing?.features?.map(({ key }) => key).includes(feature),
)

export const getIsManagerChildBusinessUnits = createSelector(
  [selectUser, (_state, selectedBusinessUnit: BusinessUnit | null) => selectedBusinessUnit],
  (user, selectedBusinessUnit) =>
    selectedBusinessUnit?.id === user?.businessUnitId ||
    selectedBusinessUnit?.ancestorIds?.some((id) => id === user?.businessUnitId),
)
